import React, { useState } from 'react';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { Box, Typography, Select, MenuItem, TextField, Button, FormControl, InputLabel } from '@mui/material';
import * as ExcelJS from 'exceljs';

const ReportPage = () => {
  const [reportType, setReportType] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [error, setError] = useState('');

  const validateForm = () => {
    if (!reportType) {
      setError('Please select a report type.');
      return false;
    }
    if (!fromDate || !toDate) {
      setError('Please select both From and To dates.');
      return false;
    }
    if (new Date(fromDate) > new Date(toDate)) {
      setError('From date cannot be after To date.');
      return false;
    }
    setError('');
    return true;
  };

  const handleGenerateExcel = (data) => {
    console.log(data, "testdata");
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');
  
    // Set headers based on the structure of the data
    const headers = Object.keys(data[0]);
    worksheet.addRow(headers);
  
    // Add rows from the data
    data.forEach((item) => {
      worksheet.addRow(Object.values(item));
    });
  
    // Set the column width for all columns based on the longest data
    headers.forEach((header, index) => {
      const maxLength = Math.max(
        ...data.map((item) => (item[header] ? item[header].toString().length : 0)),
        header.length
      );
      worksheet.getColumn(index + 1).width = maxLength + 2; // Add some padding to the width
    });
  
    // Create a buffer and download it
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${reportType}_report.xlsx`;
      link.click();
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await jwtAxios.get(`/reports/${reportType.toLowerCase()}`, {
        params: {
          from: fromDate,
          to: toDate,
        },
      });
      console.log('Report Data:', response.data);
      handleGenerateExcel(response.data.data); // Pass the fetched data to generate Excel
    } catch (err) {
      console.error('Error fetching report:', err);
      setError('Failed to fetch report data.');
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="70vh" bgcolor="grey.100">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          maxWidth: 400,
          p: 3,
          bgcolor: 'background.paper',
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" component="h2" align="center" gutterBottom>
          Generate Report
        </Typography>

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="reportType-label">Report Type</InputLabel>
          <Select
            labelId="reportType-label"
            id="reportType"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            label="Report Type"
          >
            <MenuItem value=""><em>Select Report Type</em></MenuItem>
            <MenuItem value="sales">Sales</MenuItem>
            <MenuItem value="sparepartsreport">Service Spare</MenuItem>
            <MenuItem value="purchase">Purchase</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="fromDate"
          label="From Date"
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          id="toDate"
          label="To Date"
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />

        {error && (
          <Typography color="error" variant="body2" align="center">
            {error}
          </Typography>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Generate Report
        </Button>
      </Box>
    </Box>
  );
};

export default ReportPage;
