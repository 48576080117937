import React, { useState } from "react";
import List from "@mui/material/List";
import NavVerticalGroup from "./VerticalNavGroup";
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";
import { RouterConfigData } from "@crema/types/models/Apps";

type Props = {
  routesConfig: RouterConfigData[];
};

const VerticalNav = ({ routesConfig }: Props) => {
  const [openItemId, setOpenItemId] = useState<string | null>(null); // Track the currently open item

  const handleToggle = (itemId: string) => {
    setOpenItemId((prevOpenItemId) => (prevOpenItemId === itemId ? null : itemId)); // Toggle the section
  };

  return (
    <List
      sx={{
        position: "relative",
        padding: 0,
      }}
      component="div"
    >
      {routesConfig.map((item: RouterConfigData) => (
        <React.Fragment key={item.id}>
          {item.type === "group" && <NavVerticalGroup item={item} level={0} />}

          {item.type === "collapse" && (
            <VerticalCollapse
              item={item}
              level={0}
              open={openItemId === item.id} // Only open if the item is the current open item
              onToggle={() => handleToggle(item.id)} // Handle toggle for this item
            />
          )}

          {item.type === "item" && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default VerticalNav;
